import React from 'react';
import { ChevronLeft, FirstPage, ZoomIn, ZoomOut } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

const ReportButtons = ({
  report,
  filterValsState,
  activeLevelState,
  linkedReportIdState,
  reportStatesState,
  fromDashBoard,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hiddenParam = location.state;
  const { setFilterVals, filterVals } = filterValsState;
  const { setActiveLevel, activeLevel } = activeLevelState;
  const { linkedReportId, setLinkedReportId } = linkedReportIdState;
  const { reportStates, setReportStates } = reportStatesState;

  const handleClick = () => {
    //console.log(report.id);

    //@TODO save the report data, do not retrieve it again
    // SOS by TA
    if (hiddenParam?.isZoomed) {
      return navigate(-1);
    }
    return navigate(`/app/bi/biPage/${report.id}`, { state: { isZoomed: true } });
  };

  return (
    <div className="m-2 flex justify-between min-h-[35px]">
      <div className="col-span-1 flex justify-center space-x-2">
        {(fromDashBoard || hiddenParam?.isZoomed) && (
          <button
            type="button"
            onClick={() => {
              handleClick();
            }}
            className="text-blue-600 items-center px-2 border rounded-md hover:bg-gray-100 hover:border-gray-300 shadow-md transition-shadow"
          >
            {hiddenParam?.isZoomed ? <ZoomOut /> : <ZoomIn />}
          </button>
        )}
      </div>
      {(activeLevel !== null || linkedReportId) && (
        <div className="col-span-1 flex justify-center space-x-2">
          <button
            type="button"
            onClick={() => {
              setFilterVals((prev) => {
                const defFilter = prev.filter.filter((f) => f.isDefault);

                return { ...prev, filter: defFilter };
              });
              setActiveLevel(null);
              setLinkedReportId(null);
              setReportStates([]);
            }}
            className="text-blue-600 items-center px-2 border rounded-md hover:bg-gray-100 hover:border-gray-300 shadow-md transition-shadow"
          >
            <FirstPage />
          </button>
          <button
            type="button"
            onClick={() => {
              if (reportStates.length > 0 && activeLevel === null) {
                setReportStates((prev) => {
                  const lastState = prev.pop();
                  setFilterVals(lastState.filter);
                  setActiveLevel(lastState.activeLevel);
                  setLinkedReportId(lastState.linkedReportId);
                  return prev;
                });
              } else {
                const newArray = filterVals.filter.slice(0, -1);
                setFilterVals({ filter: newArray });
                setActiveLevel((prev) => {
                  if (prev === 0) return null;
                  return prev - 1;
                });
              }
            }}
            className="text-blue-600 items-center px-2 border rounded-md hover:bg-gray-100 hover:border-gray-300 shadow-md transition-shadow"
          >
            <ChevronLeft />
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportButtons;
