import React, { useCallback, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import biService from '../../../service/bi.service';
import DrillButtons from './ReportButtons';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

const PieReport = ({ report, linkedReportIdState, activeLevelState, filterValsState, reportStatesState, height }) => {
  const [chartData, setChartData] = useState({});
  const [chartHeight, setChartHeight] = useState(650);

  const { activeLevel, setActiveLevel } = activeLevelState;
  const { filterVals, setFilterVals } = filterValsState;
  const { linkedReportId, setLinkedReportId } = linkedReportIdState;
  const { reportStates, setReportStates } = reportStatesState;

  const getChartData = useCallback(() => {
    biService.getReportData(report.id, activeLevel, filterVals).then((data) => setChartData(data));
  }, [report.id, activeLevel]);

  useEffect(() => {
    const prevReport = reportStates[reportStates.length - 1];
    if (Object.keys(report).length > 0 && prevReport?.reportId !== report.id) {
      getChartData();
    }
  }, [report, getChartData, activeLevel, reportStates]);

  useEffect(() => {
    if (height) setChartHeight(height);
  }, [height]);

  const data = {
    labels: chartData.label,
    datasets: [
      {
        label: '',
        data: chartData.data,
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      colors: {
        forceOverride: true,
      },
      title: {
        display: true,
        text: report.name,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { biDrillingLink, biDrilling, biField, id } = report;

        const label = data.labels[elements[0].index];
        const maxLevel = biDrilling.length > 0 ? Math.max(...biDrilling.map((drill) => drill.level)) : null;
        let actLevel = activeLevel !== null ? activeLevel + 1 : 0;

        let filterField;

        if (actLevel === 0 || actLevel === null) {
          filterField = biField.find((f) => f.isGroup)?.field;
        } else {
          filterField = biDrilling?.find((f) => {
            return f.level === activeLevel;
          })?.field;
        }

        const newFilter = { ...filterVals };

        if (maxLevel + 1 === actLevel || (biDrillingLink && maxLevel === null)) {
          if (biDrillingLink) {
            setReportStates((prev) => {
              prev.push({
                reportId: id,
                linkedReportId: linkedReportId,
                //@TODO Bandit megkérdezni
                filter: { filter: newFilter.filter.map((data) => data) },
                //filter: newFilter,
                activeLevel,
              });
              return prev;
            });
            setLinkedReportId(biDrillingLink.linkedReport);

            newFilter.filter.push({ field: filterField, value: label });

            const updatedFilter = newFilter.filter.map((item) => {
              const newReportField = biDrillingLink.biDrillingLinkField.find(
                (linkField) => linkField.parentField === item.field
              );
              if (newReportField) {
                return { ...item, field: newReportField.childField };
              }
              return item;
            });

            setFilterVals({ filter: updatedFilter });
            setActiveLevel(null);
          } else {
            return;
          }
        } else {
          newFilter.filter.push({ field: filterField, value: label });
          if (!biDrilling.length > 0) {
            return;
          }
          setActiveLevel(actLevel);
          setFilterVals(newFilter);
        }
      }
    },
  };

  return (
    <>
      <div className="m-2 flex justify-center" style={{ height: `${chartHeight}px` }}>
        <Pie data={data} options={options} />
      </div>
    </>
  );
};

export default PieReport;
